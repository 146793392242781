import React from 'react';
import { Header, Button, Container, Grid, Image } from 'semantic-ui-react';

const WelcomePage = () => {
  const handlePayment = () => {
    // Redireccionar a la página de pago de Mercado Pago
    window.location.href = 'https://mpago.la/1nZE5ZM';
  };

  const buttonStyle = {
    fontSize: '1.5em',
    DborderRadius: '30px',
    width: '-webkit-fill-available'
  };

  return (
    <Container text className='container' style={{ marginTop: '50px' }}>
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column width={6} textAlign="center">
            <Image src={`${process.env.PUBLIC_URL}/ucubs-logo.png`} size='medium' centered />
          </Grid.Column>
          <Grid.Column width={10} >
            <Grid.Row textAlign="justified">
            <Header as="h1">Bienvenido a Nuestro Sitio</Header>
            <p>
              Hola bienvenidos.
              Este es un sitio creado con fines académicos para aplicar conceptos de emprendedurismo, en el marco de la realización de un MBA y es responsabilidad del grupo de estudiantes. El fin es juntar fondos a partir de una inversión semilla de $ 500.<br></br>
              Se pondrá a disposición la compra de un boleto con costo $50, el cual habilitará la participación en un sorteo, con un premio de valor equivalente al 30% del monto total de lo recaudado por los boletos vendidos, con una base inicial de $500.<br></br>
              Por ejemplo, si se venden 300 boletos, el premio tendrá un valor equivalente a $4.500.<br></br>
              La venta estará habilitada solamente durante 3 horas, el día 5 de agosto, en el horario de 16:00 a 19:00 horas.
              Para tu seguridad, el abono se realiza a través de Mercado Pago.
              Link para participar del sorteo:
            </p>
            <br></br>
            </Grid.Row>
            <Grid.Row style={{ textAlign: 'center' }}>
              
            <Button primary onClick={handlePayment} style={buttonStyle}>
              Pagar con Mercado Pago
            </Button>
            
            </Grid.Row>
            <br></br>
            <p>
              Tené en cuenta que cuantos más seamos, más atractivo será el premio… te invitamos a compartir el link!!!<br></br>
              Luego de confirmar el pago, presionar el botón de “Volver al sitio” para ser redirigido a una nueva página web donde se le pedirá registrar datos personales y el número de comprobante del pago que le fuese informado al pagar. Este paso es imprescindible para participar del sorteo.
            </p>
            <Grid.Row textAlign="justified"></Grid.Row>
            
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={6} divided>
        <Grid.Row>
          <Grid.Column textAlign='center'><Image src={`${process.env.PUBLIC_URL}/image1.jpg`} size='medium' centered /></Grid.Column>
          <Grid.Column textAlign='center'><Image src={`${process.env.PUBLIC_URL}/image2.jpg`} size='medium' centered /></Grid.Column>
          <Grid.Column textAlign='center'><Image src={`${process.env.PUBLIC_URL}/image3.jpg`} size='medium' centered /></Grid.Column>
          <Grid.Column textAlign='center'><Image src={`${process.env.PUBLIC_URL}/image4.jpg`} size='medium' centered /></Grid.Column>
          <Grid.Column textAlign='center'><Image src={`${process.env.PUBLIC_URL}/image5.jpg`} size='medium' centered /></Grid.Column>
          <Grid.Column textAlign='center'><Image src={`${process.env.PUBLIC_URL}/image6.jpg`} size='medium' centered /></Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default WelcomePage;
