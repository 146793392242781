import axios from 'axios';

const backendUrl = 'https://mbaproy.softing.uy';
// Ejemplo de llamada a la API para obtener el total de pagos
const getTotalPayments = async () => {
  try {
    const response = await axios.get(`${backendUrl}/total-payments`);
    return response.data.prize;
  } catch (error) {
    console.error('Error fetching total payments:', error);
    return null;
  }
};

// Ejemplo de llamada a la API para guardar un contacto
const saveContact = async (contact) => {
  try {
    const response = await axios.post(`${backendUrl}/contacts`, contact);
    return response.data;
  } catch (error) {
    console.error('Error saving contact:', error);
    return null;
  }
};

export { getTotalPayments, saveContact };
