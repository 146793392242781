import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Header, Input, Message, Card, Grid, Image } from 'semantic-ui-react';
import { saveContact, getTotalPayments } from '../api';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [total, setTotal] = useState(0);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const fetchTotal = async () => {
      const total = await getTotalPayments();
      setTotal(total);
    };

    fetchTotal();
  }, []);

  const handleSubmit = async () => {
    if (!name || !email || !paymentId) {
      alert('Todos los campos son obligatorios');
      return;
    }
    const contact = { name, email, paymentId };
    const response = await saveContact(contact);
    if (response) {
      setEdit(true);
      setTotal(response.prize);
	  setName('');
	  setEmail('');
	  setPaymentId('');
    }
  };

  const buttonStyle = {
    fontSize: '1.5em',
    padding: '15px 30px',
    borderRadius: '30px',
    width: '-webkit-fill-available'
  };

  return (
    <Container text className='container' textAlign="center" style={{ marginTop: '50px' }}>

      <Card id="formulario">
        <Grid columns={2} textAlign='center'>
          <Grid.Column width={6} style={{ marginTop: '100px' }}>
            <Grid.Row>
              <h2>Bienvenido nuevamente</h2>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '20px' }}>
              <Image src={`${process.env.PUBLIC_URL}/atencion.jpg`} size='large' centered />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid.Row style={{ marginBottom: '20px' }}>
            <Header as="h2" style={{ marginTop: '10px' }}>Registrar pago para el sorteo</Header>
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <Input
                  className='input-centered'
                  placeholder='Nombre'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={edit}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Input
                  className='input-centered'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={edit}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Input
                  className='input-centered'
                  placeholder='Código Mercado Pago'
                  value={paymentId}
                  onChange={(e) => setPaymentId(e.target.value)}
                  disabled={edit}
                  required
                />
              </Form.Field>
              <Button primary type='submit' style={buttonStyle}>Enviar</Button>
            </Form>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '20px' }}>
              <Message positive >
                <Header as={'h2'}>Premio Acumulado</Header>
                <h2> ${500 + total}</h2>
              </Message>
              
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Card>


    </Container>
  );
};

export default ContactPage;
